<template>
<div >
    <div>
       <b-card no-body class="border mt-1">
        <b-card-header class="p-1">
            <div>
            <b-card-title>{{$t('curlang')=='en'?'Validate Buys':'Validar Compras'}}</b-card-title>
            </div>
            <div>
            <b-form-group>
        <b-input-group>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            :placeholder="$t('curlang')=='en'?'Filter by Search':'Filtrar por Busqueda'"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              {{this.$t('curlang')=='en'?'Clear':'Limpiar'}}
            </b-button>
          </b-input-group-append>

          <b-input-group-append class="ml-1">
            <feather-icon
          icon="DownloadIcon"
          size="21"
           class="cursor-pointer"
           @click="downloadfile"
            v-b-tooltip.hover.top="'Download CSV'"
        />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
        </div>
        </b-card-header> 
        <b-table
         :busy="isBusy"
          hover
        striped
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        :filter-ignored-fields="['imagenUrl']"
         @row-clicked="BuyDetail"
        selectable
        select-mode="range"
        >
          <template #table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
             <template #cell(price)="data">
       <h5>{{data.value}}<i class="fa fa-crown"></i></h5>
      </template>
       <template #cell(especial.discount)="data">
       <h5>{{data.value?data.value:0}}<i class="fa fa-crown"></i></h5>
      </template>
       <template #cell(stages[0].stageName)="data">
        <b-badge
        :variant="'light-'+variantTimeline(data.value)"
        >
       {{data.value}}
        </b-badge>
      </template>
        </b-table>
   
         <b-col cols="12">
       <b-pagination
       @change="handlePage"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="md"
              class="my-0 mt-1 mb-1"
            />
    </b-col>
       </b-card>
        <b-modal
        v-model="openmodal"
      id="modal-1"
      title="Buy Details"
      ok-title="Validate"
      @ok="saveVerifyImg"
          ok-only
    >
        <b-row>
        <b-col cols="6">
        <b-card-title>{{datamodal.title}}</b-card-title>
        </b-col>
    </b-row>
         <b-form-group label="Invitation Wallet" v-if="datamodal.especial!=undefined && datamodal.especial.type!=8">
          <b-form-input
          v-model="datamodal.especial.invitationwallet"
            type="text"
            disabled
          />
        </b-form-group>
         <b-form-group label="Whitelist Wallet" v-if="datamodal.especial!=undefined && datamodal.especial.type!=8">
          <b-form-input
          v-model="datamodal.especial.whitelistwallet"
            type="text"
            disabled
          />
        </b-form-group>
    </b-modal>
    </div>
    <div v-if="isapprovedBuy" class=" text-center">
   <b-spinner
        style="width: 3rem; height:3rem;"
        class="text-success"
      />
</div>
    </div>
</template>
<script>
import {BCard,BCardHeader,BCardTitle,BCardBody,BCardText,BTable,BSpinner,BAvatar,BModal,BImg,BAlert,
BRow,BCol,BFormInput,BFormGroup,BInputGroup,BInputGroupAppend,BButton,BPagination,BBadge,VBTooltip} from "bootstrap-vue"
import SellerService from '@/services/SellerService'
import Ripple from 'vue-ripple-directive'
export default {
    directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
    components:{BCard,BCardHeader,BCardTitle,BCardBody,BTable,BSpinner,BAvatar,BModal,BCardText,BImg,BAlert,
    BRow,BCol,BFormInput,BFormGroup,BInputGroup,BInputGroupAppend,BButton,BPagination,BBadge},
    data(){
        return{
            isBusy:true,
            isapprovedBuy:false,
            openmodal:false,
              fields:[
          {label:this.$t('curlang')=='en'?"User":"Usuario",key:"username"}, 
          {label:this.$t('curlang')=='en'?"DiscordId":"DiscordId",key:"discoruserid"},              
        {label:this.$t('curlang')=='en'?"Item":"Articulo",key:"title"},
        {label:this.$t('curlang')=='en'?"Price":"Precio",key:"price"},
        {label:this.$t('curlang')=='en'?"Discount":"Descuento",key:"especial.discount"},
        {label:this.$t('curlang')=='en'?"Invitation Wallet":"Invitation Wallet",key:"especial.invitationwallet"},
        {label:this.$t('curlang')=='en'?"Whitelist Wallet":"Whitelist Wallet",key:"especial.whitelistwallet"},
          {label:this.$t('curlang')=='en'?"Current Status":"Estado Actual",key:"stages[0].stageName"},
          {label:this.$t('curlang')=='en'?"Time Ago":"Hace un Tiempo",key:"stages[0].shortDate"}],
            items:[],
            datamodal:[],
          perPage: 5,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pposition:{start:0,end:5}
        }
    },
    mounted(){
        this.getItemBuy()
    },
    methods:{
         onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    existOn:function(arr,arr2){
      return arr2.every(v => arr.includes(v))
    },
    handlePage:async function(value){
      if(value>this.currentPage){
        
        if(this.items.length<this.totalRows){
          this.pposition.start+=5
        this.isBusy=true
          const gib=(await SellerService.getItemBuy(JSON.parse(localStorage.getItem("auth")).discordId,this.pposition)).data
             
        if (!this.existOn(this.items,gib.info)){
          this.items=this.items.concat(gib.info);
            this.totalRows = gib.rows
        }
        }
      
      }
      else{
        this.pposition.start-=5
      }
     
           this.isBusy=false
      this.currentPage=value;
       
    },
        getItemBuy:async function(){
            const gib=(await SellerService.getItemBuy(JSON.parse(localStorage.getItem("auth")).discordId,this.pposition)).data
            this.items=gib.info
               this.totalRows = gib.rows
           this.isBusy=false
        },
        BuyDetail:function(item){
            this.datamodal=[]
            this.datamodal=item
            this.openmodal=true
        },
        saveVerifyImg:function(){
            this.$swal({
        title: 'Are you sure to update this Buy?',
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Update!',
        cancelButtonText:'No, Disapprove',
        customClass: {
          confirmButton: 'btn btn-outline-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isapprovedBuy=true
           const ins=(await SellerService.approveBuyItem(JSON.parse(localStorage.getItem("auth")).discordId,this.datamodal._id,
            this.datamodal.stages[0].stageName,this.$t('curlang')))
            this.pposition={start:0,end:5}
            this.getItemBuy()
            this.isapprovedBuy=false
          this.$swal({
            icon: 'success',
            title: '',
            text: 'The Buy is Updated',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })

        }
        else if (result.dismiss === 'cancel') {
           this.isapprovedBuy=true
           const del=(await SellerService.approveBuyItem(JSON.parse(localStorage.getItem("auth")).discordId,this.datamodal._id,
            'rejected',this.$t('curlang')))
             this.getItemBuy()
              this.isapprovedBuy=false
          this.$swal({
            title: 'Request not Approve',
            text: 'The Buy is not Approve',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }

        this.$router.go(0);
      })
        },
            variantTimeline(n){
        if (n=='pending') {
            return 'danger'
        }
        if (n=='approved') {
            return 'warning'
        }
        if (n=='delivered') {
            return 'success'
        }
    },
        downloadfile:async function(){
               
  const link = document.createElement("a");
          link.href = "/api/v1/strd/dsx/"+JSON.parse(localStorage.getItem("auth")).discordId;
          link.setAttribute("download", 'CollectionHolders.xlsx');
        document.body.appendChild(link);
        link.click();
   
           
                }
    }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>